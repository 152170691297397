import { useState, useEffect, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './UseLocalStorage';
import config from 'config';
import { set } from 'lodash';
import request from 'utils/request';

const AuthContext = createContext(null);

const AuthProvider = ({ noPriv, children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useLocalStorage('user', null);
    const [client, setClient] = useState(null);

    const [message, setMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleLogin = async (values) => {
        try {
            setMessage(null);

            const data = {
                email: values.email,
                password: values.password
            };
            const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            };

            const response = await fetch(process.env.REACT_APP_API_PATH + '/login', settings);

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }

            const json = await response.json();
            if (json.r == 1 && json.user) {
                const user = json.user;

                setUser(user);

                console.log(user);

                if (!user.selectedQuoter) {
                    console.log('no quoter selected');
                    navigate('/settings');
                    return;
                }

                navigate(user.selectedQuoter + '/request/dashboard');
            } else {
                setMessage('E-mail or password is incorrect.');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlePasswordForgot = async (values) => {
        try {
            setMessage(null);

            const data = {
                email: values.email
            };
            const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            };

            const response = await fetch(process.env.REACT_APP_API_PATH + '/password-forgot', settings);

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }

            const json = await response.json();
            console.log(json);
            setSuccessMessage('Password reset instructions sent to your e-mail.');
        } catch (error) {
            console.error(error);
        }
    };

    const handlePasswordReset = async (values) => {
        try {
            setMessage(null);

            const data = {
                password: values.password,
                token: values.token
            };
            const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            };

            const response = await fetch(process.env.REACT_APP_API_PATH + '/password-reset', settings);

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }

            const json = await response.json();
            if (json.r == 1) {
                setSuccessMessage('Password reset successful.');
            } else {
                setMessage('Password reset failed.');
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const loadClient = async () => {
            try {
                setMessage(null);
                const json = await request('/client', false, {});
                if (json === false) {
                    console.log('Unauth access');
                    localStorage.setItem('user', null);
                    setUser(null);
                    location.pathname = '/login';
                    return;
                }
                if (json.r == 1) {
                    setClient(json.client);
                } else {
                    localStorage.setItem('user', null);
                    setUser(null);
                    location.pathname = '/login';
                }
            } catch (error) {
                console.error(error);
                localStorage.setItem('user', null);
                setUser(null);
                location.pathname = '/login';
            }
        };

        if (user) {
            loadClient();
            return;
        }
        if (!noPriv && location.pathname.indexOf('/login/') === -1) location.pathname = '/login/';
    }, [user]);

    const handleLogout = () => {
        setUser(null);
    };

    const handleRegister = async (values) => {
        try {
            const json = await request('/register', values, {});
            return json;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const handleFinishRegistration = async (clientId, companyId, subscriptionId, paypalId, orderId, accessToken) => {
        try {
            const json = await request('/finish-registration', { clientId, companyId, subscriptionId, paypalId, orderId, accessToken }, {});
            return json;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const value = {
        user,
        client,
        message,
        successMessage,
        setClient: setClient,
        onLogin: handleLogin,
        onPasswordForgot: handlePasswordForgot,
        onPasswordReset: handlePasswordReset,
        onLogout: handleLogout,
        handleRegister: handleRegister,
        handleFinishRegistration: handleFinishRegistration
    };

    if (!client && !noPriv) {
        children = '';
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};

export const authHeader = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
        // for Node.js Express back-end
        return {
            'x-access-token': user.accessToken,
            'Content-Type': 'application/json'
        };
    } else {
        return {
            'Content-Type': 'application/json'
        };
    }
};

export const authResponse = (response) => {
    if (response.status == 403) {
        console.log(location);
        location.pathname = '/backend/login';
        return true;
    }

    return false;
};
